<!--
 * @Author: nice
 * @Date: 2021-11-12 21:54:48
 * @LastEditTime: 2021-12-14 19:58:55
 * @LastEditors: nice
 * @Description: 账户信息
-->
<template>
  <section class="h100 account-info">
    <NavBar title="账户信息" background="blue" @leftClick="leftClick"/>
    <van-notice-bar v-show="!platformStatus.enable" scrollable :text="this.platformStatus.msg" background="opacity"
                    left-icon="volume-o"/>
    <div class="name-phone">
      <span>缴费户名 &nbsp;&nbsp;&nbsp;&nbsp;{{ accountData.customerName }}</span>
      <span>手机号 &nbsp;&nbsp;&nbsp;&nbsp; {{ accountData.phone }}</span>
      <!-- <b @click="stored">账户预存</b> -->
    </div>
    <template v-if="accountData.detailList && accountData.detailList.length !== 0">
      <div class="info-list-wrap">
        <van-cell
          v-for="(item, index) in accountData.detailList"
          :key="index"
          :title="`用户编号 ${item.customerNumber || ''}`">
<!--          <template #default>-->
<!--            <span class="blue" @click="goToArrears(item)">立即缴费</span>-->
<!--            <span class="blue" @click="goToPay(item)">充值上表</span>-->
<!--          </template>-->
          <template #label>
            <div>表号： {{ item.deviceNumber || '' }}</div>
            <div>地址： {{ item.fullAddress || '' }}</div>
            <div>账户余额： {{ item.childAccountResidual || item.childAccountResidual == 0 ? item.childAccountResidual : '' }}元</div>
            <van-row gutter="20" class="operate-btn">
              <van-col span="6">
                <div class="btn-skip" @click="goContinuePay(item)">
                  <span class="blue" >支付中</span>
                </div>
              </van-col>
              <van-col span="6">
                <div class="btn-skip" @click="goPaymentRecords(item)">
                  <span class="blue" >缴费记录</span>
                </div>
              </van-col>
              <van-col span="6">
                <div class="btn-skip" v-if="item.meterTypeCode && (item.meterTypeCode.type4Revenue === 1 || item.meterTypeCode.type4Revenue === 2)" @click="goToPay(item)">
                  <span class="blue">充值上表</span>
                </div>
                <div class="btn-skip" v-else @click="stored(item)">
                  <span class="blue">账户预存</span>
                </div>
              </van-col>
              <van-col span="6">
                <div class="btn-skip" @click="goToArrears(item)">
                  <span class="blue">立即缴费</span>
                </div>
              </van-col>
            </van-row>
            <span class="tip-arrear" v-if="item.arrearsFlag">当前表号有欠费记录</span>
          </template>
        </van-cell>
      </div>
    </template>
    <template v-else>
      <van-empty v-if="!loading" description="暂无数据" />
    </template>
  </section>
</template>

<script>
import {getCustomerNumber4AppVo, getPlatformStatus} from '@/services/payServer.js';
import { Toast } from 'vant';

export default {
  name: 'AccountInfo',
  data() {
    const { keyword } = this.$route.query;
    return {
      loading: false,
      userValue: keyword,
      accountData: {},
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    };
  },
  created() {
    this.hideshare();
    this.getAccountInfo();
    this.loadPlatformStatus();
  },
  methods: {
    onBridgeReady(){
      WeixinJSBridge.call('hideOptionMenu');
    },
    hideshare () {
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      }else{
        this.onBridgeReady();
      }
    },

    // 自定义返回
    leftClick () {
      this.$router.push({ name: 'payHome'});
    },
    // 获取用户信息
    getAccountInfo() {
      getCustomerNumber4AppVo({ queryParam: this.userValue })
        .then(res => {
          if (+res.code === 9200) {
            this.accountData = res.data || {};
          } else {
            Toast.fail(res.msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    isShowPay(item) {
      return item?.meterTypeCode?.type4Revenue === 1 || item?.meterTypeCode?.type4Revenue === 2;
    },
    // 账户预存
    stored(item) {
      this.$router.push({ name: 'stored', query: { keyword: this.userValue, selfType: 'stored', ...item } });
      // this.$router.push({ name: 'stored', query: { keyword: this.userValue, selfType: 'stored' } });
    },
    // 充值上表
    goToPay(item) {
      this.$router.push({ name: 'stored', query: { keyword: this.userValue, selfType: 'meter', ...item } });
    },
    // 立即缴费
    goToArrears(item) {
      this.$router.push({ name: 'arrearsRecords', query: { item: JSON.stringify(item), keyword: this.userValue } });
    },
    // 缴费记录
    goPaymentRecords (item) {
      this.$router.push({
        name: 'paymentRecords',
        query: { accountId: this.accountData.customerAccountId, customerNumberId: item.customerNumberId, keyword: item.customerNumber }
      });
    },
    // 支付中
    goContinuePay (item) {
      this.$router.push({
        name: 'continuePay',
        query: { accountId: this.accountData.customerAccountId, customerNumberId: item.customerNumberId, keyword: item.customerNumber }
      });
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then(res => {
        let platform = this.util.getTranscateRst(res);
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    }
  }
};
</script>

<style lang="less">
.account-info {
  .tip-arrear {
    display: block;
    border-radius: 3px;
    background-color: rgba(255, 141, 141, 0.1);
    color: rgba(255, 141, 141, 100);
    font-size: 12px;
    height: 19px;
    line-height: 19px;
    padding: 2px 5px;
    border: 1px solid rgba(255, 141, 141, 100);
    margin-top: 5px;
    width:fit-content;
  }
  .name-phone {
    background: #4284f3;
    height: 70px;
    position: relative;
    padding: 13px;
    color: #fff;
    span {
      display: block;
      margin-top: 10px;
    }
    b {
      font-weight: 100;
      display: inline-block;
      padding: 3px 10px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      position: absolute;
      right: 13px;
      bottom: 30px;
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  .info-list-wrap {
    overflow-y: auto;
    padding: 10px 13px;
    height: calc(100vh - 46px - 25px - 70px - 26px - 20px);
    .van-cell {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    .van-cell__title {
      flex-shrink: 0;
      width: 100%;
      flex: initial;
    }
  }
  .operate-btn{
    padding: 15px 0 10px 0;
  }
  .btn-skip{
    border: 1px solid #4284f3;
    border-radius: 5px;
    text-align: center;
    padding: 6px 0;
  }
}
</style>
